import { mutationStore, queryStore } from '@urql/svelte';
import {
  GetProfileDocument,
  type CancelSubscriptionMutation,
  type GetProfileQuery,
  type GetProfileQueryVariables,
  type CancelSubscriptionMutationVariables,
  CancelSubscriptionDocument,
  type AcceptTermsMutation,
  type AcceptTermsMutationVariables,
  AcceptTermsDocument,
  type AcceptTermsResultInput,
} from './generated';
import { client } from './client';

export const createProfileStore = (
  { pause }: { pause?: boolean } = { pause: false }
) => {
  const qs = queryStore<GetProfileQuery, GetProfileQueryVariables>({
    client,
    query: GetProfileDocument,
    pause,
  });

  return {
    ...qs,
    refresh: () => {
      qs.reexecute({});
    },
  };
};

export const cancelSubscription = (
  { paused }: { paused: boolean } = { paused: false }
) => {
  return mutationStore<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >({
    client,
    query: CancelSubscriptionDocument,
    context: {
      additionalTypenames: ['UserProfile'],
    },
  });
};

export const acceptTerms = (input: AcceptTermsResultInput) => {
  return mutationStore<AcceptTermsMutation, AcceptTermsMutationVariables>({
    client,
    query: AcceptTermsDocument,
    variables: {
      input,
    },
  });
};
