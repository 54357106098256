import { writable, derived } from 'svelte/store';

const TIMEOUT = 3000;

export enum ToastType {
  default = 'default',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

export type Toast = {
  id: string;
  type: ToastType;
  message: string;
  description?: string;
  timeout: number;
};

function id() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

const _toasts = writable<Toast[]>([]);

function sendMessage(
  type: ToastType = ToastType.default,
  message: string,
  description: string = '',
  timeout: number = TIMEOUT
) {
  _toasts.update((state) => {
    return [...state, { id: id(), type, message, description, timeout }];
  });
}

function clearToast(id: string) {
  _toasts.update((state) => {
    return state.filter((toast) => toast.id !== id);
  });
}

const toastsStore = derived(_toasts, ($_toasts, set) => {
  set($_toasts);
  if ($_toasts.length > 0) {
    const timer = setTimeout(() => {
      clearToast($_toasts[0].id);
    }, $_toasts[0].timeout);
    return () => {
      clearTimeout(timer);
    };
  }
});

export default {
  subscribe: toastsStore.subscribe,
  default: (
    message: string,
    description: string = '',
    timeout: number = TIMEOUT
  ) => sendMessage(ToastType.default, message, description, timeout),
  danger: (
    message: string,
    description: string = '',
    timeout: number = TIMEOUT
  ) => sendMessage(ToastType.danger, message, description, timeout),
  warning: (
    message: string,
    description: string = '',
    timeout: number = TIMEOUT
  ) => sendMessage(ToastType.warning, message, description, timeout),
  info: (
    message: string,
    description: string = '',
    timeout: number = TIMEOUT
  ) => sendMessage(ToastType.info, message, description, timeout),
  success: (
    message: string,
    description: string = '',
    timeout: number = TIMEOUT
  ) => sendMessage(ToastType.success, message, description, timeout),
  clear: clearToast,
};
