import { derived, writable, type Readable, get } from 'svelte/store';
import { getAuth, type User } from 'firebase/auth';
import firebaseStore from './firebaseStore';
import FirebaseAuthService from '../services/firebase.auth.service';

export const enum AuthState {
  UNKNOWN = 'UNKNOWN',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export const authState = writable<AuthState>(AuthState.UNKNOWN);

export const speechifyMobileUser = derived<typeof firebaseStore, User | null>(
  firebaseStore,
  ($firebaseStore, set) => {
    if (!$firebaseStore.speechifyMobile) {
      return;
    }
    const speechifyMobileAuth = getAuth($firebaseStore.speechifyMobile);
    return speechifyMobileAuth.onAuthStateChanged(async (user) => {
      if (user) await FirebaseAuthService.signIntoVideoStudio(user);
      else authState.set(AuthState.LOGGED_OUT);
      set(user);
    });
  }
);

export const user = derived<
  [Readable<User | null>, typeof firebaseStore],
  User | null
>(
  [speechifyMobileUser, firebaseStore],
  ([$speechifyMobileUser, $firebaseStore], set) => {
    if (!$speechifyMobileUser) {
      return;
    }
    if (!$firebaseStore.videoStudio) {
      return;
    }
    const videoStudioAuth = getAuth($firebaseStore.videoStudio);
    return videoStudioAuth.onAuthStateChanged((user) => {
      set(user);
      if (user) authState.set(AuthState.LOGGED_IN);
      else authState.set(AuthState.LOGGED_OUT);
    });
  }
);

export const getCurrentUser = async (): Promise<User | null> => {
  // wait for authState to be known
  await new Promise((resolve) => {
    authState.subscribe((value) => {
      if (value !== AuthState.UNKNOWN) {
        resolve(null);
      }
    });
  });

  return get(user);
};

export const waitForUserAuth = async (userId: string): Promise<User> => {
  return await new Promise((resolve) => {
    user.subscribe((value) => {
      if (value?.uid === userId) {
        resolve(value);
      }
    });
  });
};

export const waitForLogin = async (): Promise<User> => {
  // wait for authState to be logged in
  await new Promise((resolve) => {
    authState.subscribe((value) => {
      if (value === AuthState.LOGGED_IN) {
        resolve(null);
      }
    });
  });

  // wait for user to be set
  return await new Promise((resolve) => {
    user.subscribe((value) => {
      if (value) {
        resolve(value);
      }
    });
  });
};
